/* eslint react/prop-types: 0 */
import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Comments, FacebookProvider } from 'react-facebook'
import SectionBlogPosts from '../components/SectionBlogPosts'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
// import FsLightbox from 'fslightbox'

export const query = graphql`query ($slug: String!) {
  contentfulBlog(slug: {eq: $slug}) {
    slug
    title
    categoria
    image {
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    content {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          __typename
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
            url
          }
          title
          description
        }
      }
    }
  }
}
`
export default function PostBlog (props) {
  const re = /(?:.*- )?(.*)/
  const options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const alt = node.data.target.title
        const fluid = node.data.target.localFile.childImageSharp.gatsbyImageData
        // const url = node.data.target.url
        const [toggler, setToggler] = useState(false)
        return (
          <figure className="w-75">
            <a onClick={() => setToggler(!toggler)}>
              <GatsbyImage
                image={fluid}
                className='figure-img img-fluid rounded border'
                alt={alt}
                title={alt} />
            </a>
            {/* <FsLightbox
              toggler={toggler}
              sources={[url]}
            /> */}
            <figcaption className="text-center">
              {alt.split(re)}
            </figcaption>
          </figure>
        )
      },
      // [INLINES.ENTRY_HYPERLINK]: node => defaultInline(INLINES.ENTRY_HYPERLINK, node),
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: ({ data }, children) => (
        <a
          href={data.uri}
          target="_blank"
          rel="noreferrer noopener"
        >{children}</a>
      )
    },
    renderMark: {
      // eslint-disable-next-line react/display-name
      [MARKS.CODE]: text => {
        return <p>{text}</p>
      }
    }
  }
  const pageUrl = `https://www.drogaleste.com.br/blog/${props.data.contentfulBlog.slug}`
  return (
    <Fragment>
      <Helmet>
        <title>{props.data.contentfulBlog.title} | Drogaleste</title>
        <meta
          name="title"
          content={`${props.data.contentfulBlog.title} | Drogaleste`}
        />
        <meta name="author" content="CloudDog" />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content="" />
        <meta property="og:description" content={`${props.data.contentfulBlog.subtitle}`} />
        <meta
          property="og:title"
          content={`${props.data.contentfulBlog.title} | Drogaleste`}
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content={`https://www.drogaleste.com.br/blog/${props.data.contentfulBlog.slug}/`}
        />
        <meta
          property="og:image"
          content={`https://www.drogaleste.com.br${props.data.contentfulBlog.image.localFile.publicURL}`}
        />
        <meta
          property="og:description"
          content=""
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Article",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${pageUrl}"
              },
              "headline": "${props.data.contentfulBlog.title}",
              "description": "${props.data.contentfulBlog.subtitle}",
              "image": [
                "https://www.drogaleste.com.br${props.data.contentfulBlog.image.localFile.publicURL}"
              ],
              "datePublished": "${props.data.contentfulBlog.date}",
              "dateModified": "${props.data.contentfulBlog.updatedAt}",
              "publisher": {
                "@type": "Organization",
                "name": "Drogaleste",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://www.drogaleste.com.br/logo-drogaleste.svg"
                }
              }
            }
          `}
        </script>
      </Helmet>
      <section className="container">
        <div ><GatsbyImage
          image={props.data.contentfulBlog.image.localFile.childImageSharp.gatsbyImageData}
          style={{ maxWidth: '50vw' }} /></div>
        <h1 className="mb-0">{props.data.contentfulBlog.title}</h1>
        <br />
        <div className="mt-4 mb-0">
          {renderRichText(props.data.contentfulBlog.content, options)}
        </div>
        <p><strong>--</strong></p>
        <strong>Gostou dessas dicas? Para conferir mais como essas, não deixe de acompanhar nosso blog aqui na página da Droga Leste. Tem algum assunto que você gostaria de ver por aqui? Fale com a gente pelo e-mail <a href="mailto:marketing@rededrogaleste.com.br">marketing@rededrogaleste.com.br</a>. Cuide bem, viva bem!</strong>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 pt-5">
            <div className="comentarios-text-area" style={{ width: '100%' }}>
              <FacebookProvider appId="256281040558" language="pt_BR">
                <Comments width='100%' />
              </FacebookProvider>
            </div>
          </div>
          <div className="col-lg 12 col-mb-12 pb-3">
            <SectionBlogPosts title="Confira mais" path={props.data.contentfulBlog.slug} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
